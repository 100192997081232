.pushable-front {
  font-weight: normal;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  color: white;
  transform: translateY(-3px);
  transition: transform 100ms;
  border-width: 1px;
  border-style: solid;
}

.pushable:hover .pushable-front {
  filter: brightness(1.05);
  transform: translateY(-4px);
}

.pushable:active .pushable-front {
  transform: translateY(-2px);
}