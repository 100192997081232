.chromePicker {
  border-radius: 8px !important;
  width: 100% !important;
  overflow: hidden;
}

.penModeCanvas {
  cursor: var(--cursor-default);
}

.stickerModeCanvas {
  cursor: var(--cursor-pointer);
}