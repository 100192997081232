@font-face {
  font-family: hansip;
  src: url(/public/font/Hansip.otf);
}

@font-face {
  font-family: bouncy;
  src: url(/public/font/Bouncy.otf);
}

:root {
  --button-primary: #a285d7;
  --button-shadow-primary: #7355a9;
  --background-primary: #fde9e1;
  --background-secondary: #c6f4ff;
  --text-header-primary: #9e5a3f;
  --text-header-secondary: #b86f50;
  --text-normal: #442316;
  --font-header-primary: hansip;
  --font-header-secondary: 'Poppins', serif;
  --font-normal: 'Poppins', serif;
  --cursor-default: url(/public/accessories/cursor_default.png) 16 16, auto;
  --cursor-pointer: url(/public/accessories/cursor_pointer.png) 16 16, pointer;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: url(/public/accessories/cursor_default.png) 16 16, auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Overriding toast container background color */
.css-zqqgfp {
  background: var(--button-primary) !important;
}

.fade-in {
  animation: fade-in 1s linear;
}

@keyframes fade-in {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}